<template>
  <TitleBar :actions="[]" :collapseActions="false">
    <template #custom>
      <Button
        v-if="!displayFilters"
        class="mx-2"
        label="Show Filters"
        @click="displayFilters = true"
      />
    </template>
    {{ pageTitle }}</TitleBar
  >

  <div class="px-5 pb-5">
    <div v-if="isLoading">
      <div class="grid">
        <div
          class="col-12 lg:col-4"
          v-for="i in Array(3)"
          :key="'skeleton-' + i"
        >
          <div
            class="surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between mb-3">
              <Skeleton class="p-mb-2 h-7rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div
          class="col-12 lg:col-3"
          v-for="i in Array(4)"
          :key="'skeleton-' + i"
        >
          <div
            class="surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between mb-3">
              <Skeleton class="p-mb-2 h-12rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isLoading && report">
      <div class="flex justify-content-center">
        <div class="col-4">
          <div
            class="p-grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between align-items-center mb-1">
              <h2 class="font-normal text-black-alpha-90 my-3">By Channel</h2>
            </div>
            <div>
              <DataTable
                :value="report.byChannel"
                responsiveLayout="scroll"
                showGridlines
                stripedRows
              >
                <Column field="title" header="Channel"></Column>
                <Column field="total" header="Visits"></Column>
                <Column field="leads" header="Leads"></Column>
                <Column field="conversion" header="Conv"></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div
            class="p-grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between align-items-center mb-1">
              <h2 class="font-normal text-black-alpha-90 my-3">Site Version</h2>
            </div>
            <div>
              <DataTable
                :value="report.bySiteVersion"
                responsiveLayout="scroll"
                showGridlines
                stripedRows
              >
                <Column field="title" header="Version"></Column>
                <Column field="total" header="Visits"></Column>
                <Column field="leads" header="Leads"></Column>
                <Column field="conversion" header="Conv"></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div
            class="p-grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between align-items-center mb-1">
              <h2 class="font-normal text-black-alpha-90 my-3">Device Type</h2>
            </div>
            <div>
              <DataTable
                :value="report.byDeviceType"
                responsiveLayout="scroll"
                showGridlines
                stripedRows
              >
                <Column field="title" header="Version"></Column>
                <Column field="total" header="Visits"></Column>
                <Column field="leads" header="Leads"></Column>
                <Column field="conversion" header="Conv"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <SitePerformanceChart :performance-data="this.report.byDate" />
    </div>
  </div>
  <Dialog
    header="Run Report"
    v-model:visible="displayFilters"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <div class="px-2 py-1">
      <div class="surface-card p-4 shadow-2 border-round p-fluid">
        <div class="grid formgrid p-fluid">
          <div v-if="showDomains" class="field mb-4 col-12 md:col-6">
            <label for="response_date" class="font-medium text-900"
              >Domain</label
            >
            <Dropdown
              :showIcon="true"
              :options="this.domains"
              option-label="domain"
              option-value="id"
              v-model="formFilters['domain_id']"
            ></Dropdown>
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="response_date" class="font-medium text-900"
              >Response Date Range</label
            >
            <Calendar
              :showIcon="true"
              v-model="formFilters['dateRange']"
              selection-mode="range"
              :manual-input="false"
              date-format="mm-dd-yy"
            ></Calendar>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="displayFilters = false"
      />
      <Button
        :disabled="!filtersAreSet"
        label="Apply Filters"
        icon="pi pi-check"
        autofocus
        @click="runReport"
      />
    </template>
  </Dialog>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import ClientDomain from "@/models/ClientDomain";
import Skeleton from "primevue/skeleton";
import SitePerformance from "@/models/reports/SitePerformance";
import SitePerformanceChart from "@/components/reports/SitePerformanceChart.vue";
import { useAppStore } from "@/stores/app";
import { ref } from "vue";

export default {
  components: {
    TitleBar,
    Skeleton,
    SitePerformanceChart,
  },
  setup() {
    const appStore = useAppStore();
    const displayFilters = ref(false);
    return { appStore, displayFilters };
  },
  data() {
    return {
      actions: [],
      isLoading: true,
      report: null,
      domains: [],
      pageTitle: "Site Performance",
      formFilters: { domain_id: null, dateRange: null },
    };
  },
  methods: {
    async runReport() {
      this.isLoading = true;
      this.displayFilters = false;
      this.report = await SitePerformance.where(
        "campaign_id",
        this.appStore.currentCampaign.id
      )
        .setFilters(this.formFilters)
        .first();

      this.isLoading = false;
    },
  },
  computed: {
    useFullStats() {
      return process.env.VUE_APP_FULL_STATS_OVERVIEW === "true";
    },
    filtersAreSet() {
      return (
        this.formFilters["dateRange"] !== null &&
        this.formFilters["domain_id"] !== null
      );
    },
    showDomains() {
      return this.domains.length > 1;
    },
  },
  async mounted() {
    //this.pageTitle = this.appStore.currentCampaign.name + " Campaign Overview";
    // this.isLoading = false;
    await ClientDomain.get().then((res) => {
      this.domains = res.data;
    });
    if (this.domains.length && this.domains.length === 1) {
      this.formFilters["domain_id"] = this.domains[0].id;
    }
    if (!this.filtersAreSet) {
      this.displayFilters = true;
    }
    this.isLoading = false;
  },
};
</script>
